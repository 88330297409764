// Place for temp fixes

// temp fix for scrollbar styling (wip)
// @import "scrollbar";

// TEMP BREADCRUMB FIX
.p-breadcrumb {
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 1rem;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link {
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #99f6e4;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
  color: #4b5563;
}
.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-icon {
  color: #6b7280;
}
.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
  margin: 0 0.5rem 0 0.5rem;
  color: #4b5563;
}
.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-text {
  color: #4b5563;
}
.p-breadcrumb .p-breadcrumb-list li:last-child .p-menuitem-icon {
  color: #6b7280;
}

// TEMP SPLITBUTTON
.p-splitbutton .p-splitbutton-defaultbutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-defaultbutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-defaultbutton.p-button {
  flex: 1 1 auto;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}

.p-splitbutton-menubutton,
.p-splitbutton.p-button-rounded > .p-splitbutton-menubutton.p-button,
.p-splitbutton.p-button-outlined > .p-splitbutton-menubutton.p-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
