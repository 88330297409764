// Template
@import './layout/styles/theme/lara-light-teal/theme.css';

$gutter: 1rem; //for primeflex grid system
@import './layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../../node_modules/primeng/resources/primeng.min.css';
@import '../../node_modules/primeflex/primeflex.scss';
@import '../../node_modules/primeicons/primeicons.css';

@import 'loading-indicator.scss';

// Some temp fixes
@import 'fixes';

// Flag Icons
@import 'flag-icons/sass/flag-icons.scss';

// If you want to add something do it here
@import 'custom';
