// Position mess-notification
.mess-notification {
  position: fixed;
  z-index: 10000;
  right: 20px;
  top: 20px;
}

p-card {
  .p-component.content-full {
    .p-card-body {
      height: 100%;
      .p-card-content {
        padding: 0;
        height: 100%;
      }
    }
  }
}

// Fix form
.field.grid > label {
  display: flex;
  align-items: start;
  margin-top: 0.8rem;
}

// Buttons Sidebar Collection
p-button {
  &.p-button-collection {
    .p-button {
      padding: 1rem;
    }
    .p-button-icon-left {
      width: 24px;
      text-align: left;
      margin-right: 0;
    }
    .p-button-label {
      font-weight: 400;
      text-align: left;
    }
  }

  &.p-button-config {
    &.selected {
      .b-description {
        font-weight: 700 !important;
      }
    }
    .p-button {
      padding: 0.5rem;
    }
    .p-button-icon-left {
      width: 24px;
      text-align: left;
      margin-right: 0;
    }
    .p-button-label {
      font-weight: 400;
      text-align: left;
    }
  }
}

// Accordion Sidebar Colelction
p-accordiontab {
  &.p-accordion-collection {
    .p-accordion-header-link {
      display: flex;
      flex-direction: row-reverse;
    }
    .p-accordion-header-text {
      font-weight: 400;
    }
    .p-accordion-header-link {
      width: 100%;
      padding: 1rem !important;
      font-weight: 400 !important;
      i {
        width: 24px;
      }
    }
  }
}

// Overlay collections
.overlay-download-collection {
  .p-overlaypanel-content {
    padding: 0.5rem;
    p-button {
      .p-button-icon-left {
        width: 24px;
        text-align: left;
        margin-right: 0;
      }
      .p-button-label {
        font-weight: 400;
        text-align: left;
      }
    }
  }
}

// Cytoscape
#cy-container {
  &.cy-fullscreen {
    #cy {
      height: 100vh;
    }
  }
  #cy {
    height: calc(100vh - 230px);
    width: 100%;
    overflow: hidden;
    padding: 0;
    div {
      &.cy-panzoom {
        z-index: 998 !important;
      }
      &:nth-child(2) {
        z-index: 1 !important;
      }
    }
  }
}

.min-w-80vh {
  min-width: 80vw !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: var(--surface-border) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--surface-border);
  border-radius: 10px;
  border: 3px solid #ffffff;
}

p-password {
  div.p-password.p-component {
    width: 100%;
  }
}

.fullscreen-dialog {
  max-height: 100% !important;
}

p-fileupload {
  .hide-content {
    .p-fileupload-buttonbar {
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      border-bottom: 1px solid #dee2e6;
    }
    .p-fileupload-content {
      display: none;
    }
  }
}
